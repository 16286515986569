@tailwind base;

@tailwind components;

h1,
h2 {
  @apply font-semibold leading-tight;
}

h1 {
  @apply text-4xl font-serif leading-none;
}

h2 {
  @apply text-2xl;
}

p,
li {
  @apply text-gray-600;
}

input[type="text"].is-xl,
input[type="number"].is-xl {
  @apply bg-white  w-full border-4 border-gray-200 text-5xl px-4 py-2 rounded-lg outline-none;
}

input[type="text"].is-xl:focus,
input[type="number"].is-xl:focus {
  /* @apply shadow-xl; */
  @apply shadow-outline;
}

button.Btn {
  @apply inline-flex font-semibold outline-none cursor-pointer text-xl px-5 py-2 rounded-lg bg-indigo-100 text-indigo-600;
}

button.Btn:disabled {
  @apply opacity-50;
}

button.Btn:hover {
  @apply bg-indigo-200;
}

button.Btn:focus {
  @apply shadow-outline;
}

button.Btn.is-green {
  @apply bg-green-100 text-green-600;
}

button.Btn.is-green:hover {
  @apply bg-green-200;
}

@tailwind utilities;
